var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"50px"}},[_c('div',{staticClass:"BPCheaderIcon",style:(`background-image: url(${ _vm.logoTheme() || '/favicon-primary.png'
  });`)}),_c('KTPortlet',{staticStyle:{"width":"500px","max-width":"90%","margin":"0 auto","xbackground":"var(--dark)"}},[_c('template',{slot:"body"},[_c('div',{staticClass:"loginBody"},[(_vm.form.busy)?_c('Loader',{attrs:{"inContent":true,"noText":true}}):(_vm.fullErrorMessage !== null)?[_c('h1',{staticStyle:{"margin":"0 auto","text-align":"center","margin-bottom":"40px"}},[_vm._v(" "+_vm._s(_vm.fullErrorMessage)+" ")]),_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.forceRetry()}}},[_vm._v("retry")])])]:(Object.keys(_vm.currentUser.clients).length > 0)?[_c('h1',{staticStyle:{"margin":"0 auto","text-align":"center","margin-bottom":"40px"}},[_vm._v(" Select Client ")]),_vm._l((Object.keys(_vm.currentUser.clients)),function(clientId,index){return _c('KTPortlet',{key:clientId,style:(`width: 100%;
            margin: 0 auto;
            margin-bottom: ${ index === Object.keys(_vm.currentUser.clients).length - 1
  ? '0'
  : '20px'
};
            sbackground: var(--dark);
            border: 1px solid white;
            cursor: pointer;`),attrs:{"id":"ktportsidelist"},on:{"click":function($event){return _vm.selectClient(clientId)}}},[_c('template',{slot:"body"},[_c('h3',{staticStyle:{"padding":"0","margin":"0","display":"inline-block","float":"left","width":"calc(100% - 25px)"},on:{"click":function($event){return _vm.selectClient(clientId)}}},[_vm._v(" "+_vm._s(_vm.currentUser.clients[clientId].name)+" ")]),_c('div',{staticStyle:{"display":"inline-block","float":"right","height":"25px","width":"25px"},on:{"click":function($event){return _vm.selectClient(clientId)}}},[_c('v-icon',{staticStyle:{"xzcolor":"#ffffff"}},[_vm._v("mdi-arrow-right-bold-circle")])],1)])],2)})]:_vm._e(),_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.form.busy && Object.keys(_vm.currentUser.clients).length == 0),expression:"!form.busy && Object.keys(currentUser.clients).length == 0"}],ref:"loginForm",attrs:{"role":"form","lazy-validation":""},on:{"submit":_vm.submit},model:{value:(_vm.form.loginFormValid),callback:function ($$v) {_vm.$set(_vm.form, "loginFormValid", $$v)},expression:"form.loginFormValid"}},[_c('div',{staticStyle:{"padding":"20px"}},[(_vm.form.step === 0)?_c('div',[_c('h1',{staticStyle:{"margin":"0 auto","text-align":"center","margin-bottom":"40px"}},[_vm._v(" Link Account ")]),_c('v-text-field',{ref:"loginFormEmail",attrs:{"name":"accountId","autocapitalize":"off","autocorrect":"off","spellcheck":"false","type":"text","label":"Account id/number/reference","rules":_vm.$vrules.basicRequired('Account reference'),"prefix":(_vm.layoutConfig().account || {}).referenceKey
                    ? `${ _vm.layoutConfig().account.referenceKey }-`
                    : undefined,"required":true},model:{value:(_vm.form.accountId),callback:function ($$v) {_vm.$set(_vm.form, "accountId", $$v)},expression:"form.accountId"}}),_c('p',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" On an invoice/email from us, there should be an account reference. ")])],1):_vm._e(),(_vm.form.step === 1)?_c('div',[_c('h1',{staticStyle:{"margin":"0 auto","text-align":"center","margin-bottom":"40px"}},[_vm._v(" Create Account ")]),_c('p',{staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" Please contact sales :) ")])]):_vm._e(),_c('div',{staticStyle:{"width":"10px","height":"50px"}}),(_vm.form.step === 0)?_c('b-button',{staticStyle:{"float":"right"},attrs:{"disabled":!_vm.form.loginFormValid,"variant":_vm.form.loginFormValid ? 'success' : 'danger'},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.buttonText))]):_vm._e()],1)])],2)])],2),_c('div',{staticStyle:{"margin":"0 auto","width":"100%","text-align":"center","padding-top":"20px"}},[_c('b-button',{attrs:{"variant":"text"},on:{"click":_vm.logout}},[_vm._v("LOGOUT")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }