<template>
  <div style="padding-top: 50px">
    <div class="BPCheaderIcon" :style="`background-image: url(${ logoTheme() || '/favicon-primary.png'
    });`"></div>
    <KTPortlet style="
        width: 500px;
        max-width: 90%;
        margin: 0 auto;
        xbackground: var(--dark);
      ">
      <template slot="body">
        <div class="loginBody">
          <Loader v-if="form.busy" :inContent="true" :noText="true" />
          <template v-else-if="fullErrorMessage !== null">
            <h1 style="margin: 0 auto; text-align: center; margin-bottom: 40px">
              {{ fullErrorMessage }}
            </h1>
            <div style="width: 100%; text-align: center">
              <button type="button" @click="forceRetry()">retry</button>
            </div>
          </template>
          <template v-else-if="Object.keys(currentUser.clients).length > 0">
            <h1 style="margin: 0 auto; text-align: center; margin-bottom: 40px">
              Select Client
            </h1>
            <KTPortlet id="ktportsidelist" v-for="(clientId, index) in Object.keys(currentUser.clients)"
              v-bind:key="clientId" @click="selectClient(clientId)" :style="`width: 100%;
              margin: 0 auto;
              margin-bottom: ${ index === Object.keys(currentUser.clients).length - 1
    ? '0'
    : '20px'
  };
              sbackground: var(--dark);
              border: 1px solid white;
              cursor: pointer;`">
              <template slot="body">
                <h3 @click="selectClient(clientId)" style="
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    float: left;
                    width: calc(100% - 25px);
                  ">
                  {{ currentUser.clients[clientId].name }}
                </h3>
                <div @click="selectClient(clientId)" style="
                    display: inline-block;
                    float: right;
                    height: 25px;
                    width: 25px;
                  ">
                  <v-icon style="xzcolor: #ffffff">mdi-arrow-right-bold-circle</v-icon>
                </div>
              </template>
            </KTPortlet>
          </template>
          <v-form v-show="!form.busy && Object.keys(currentUser.clients).length == 0" role="form" ref="loginForm"
            v-model="form.loginFormValid" @submit="submit" lazy-validation>
            <div style="padding: 20px">
              <div v-if="form.step === 0">
                <h1 style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  ">
                  Link Account
                </h1>
                <v-text-field v-model="form.accountId" name="accountId" ref="loginFormEmail" autocapitalize="off"
                  autocorrect="off" spellcheck="false" type="text" label="Account id/number/reference"
                  :rules="$vrules.basicRequired('Account reference')" :prefix="
                    (layoutConfig().account || {}).referenceKey
                      ? `${ layoutConfig().account.referenceKey }-`
                      : undefined
                  " :required="true"></v-text-field>
                <p style="width: 100%; text-align: center">
                  On an invoice/email from us, there should be an account
                  reference.
                </p>
              </div>
              <div v-if="form.step === 1">
                <h1 style="
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 40px;
                  ">
                  Create Account
                </h1>
                <p style="width: 100%; text-align: center">
                  Please contact sales :)
                </p>
                <!-- <v-text-field
                  v-model="form.name"
                  type="text"
                  name="first-name"
                  autocapitalize="on"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="first-name"
                  label="First Name"
                  :rules="$vrules.basicText('First Name')"
                  :required="true"
                ></v-text-field>
                <v-text-field
                  v-model="form.surname"
                  type="text"
                  name="last-name"
                  autocapitalize="on"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="last-name"
                  label="Last Name"
                  :rules="$vrules.basicText('Last Name')"
                  :required="true"
                ></v-text-field> -->
              </div>

              <div style="width: 10px; height: 50px"></div>

              <!-- <b-button
                style="float: left"
                v-if="form.step === 0"
                @click="form.step = 1"
                variant="info"
                >Create account</b-button
              > -->
              <b-button v-if="form.step === 0" style="float: right" :disabled="!form.loginFormValid" @click="submit"
                :variant="form.loginFormValid ? 'success' : 'danger'">{{ buttonText }}</b-button>
            </div>
          </v-form>
        </div>
      </template>
    </KTPortlet>
    <div style="margin: 0 auto; width: 100%; text-align: center; padding-top: 20px">
      <b-button @click="logout" variant="text">LOGOUT</b-button>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { mapGetters } from "vuex";
//import KTUtil from "@/assets/js/util";
//import KTWizard from "@/assets/js/wizard";
//import Swal from "sweetalert2";
import Loader from "@/views/partials/content/Loader.vue";
//import { VERIFY_AUTH } from "@/store/auth.module";
//import axios from "axios";
import {
  VERIFY_AUTH,
  DO_CLIENT_CHANGE,
  LOGOUT,
  FORCE_REFRESH_TOKEN,
} from "@/store/auth.module";
import Swal from "sweetalert2";

export default {
  components: {
    KTPortlet,
    Loader,
  },
  data() {
    return {
      form: {
        busy: true,
        step: 0,
        // 0: Link Account
        // 1: Create account
        loginFormValid: false,
        accountId: "",
      },
      buttonText: "Link Account",
      steps: {},
      stepsLoading: false,
      stepsStage: 0,
      wizard: null,
      fullErrorMessage: null,
    };
  },
  mounted() {
    const self = this;
    setTimeout(() => {
      if (self.busy) {
        self.$data.busy = false;
      }
    }, 8000);
    this.remount();
    //this.setupWizard();
    // Validation before going to next page
    //this.wizard.on("beforeNext", function (/*wizardObj*/) {
    // validate the form and use below function to stop the wizard's step
    // wizardObj.stop();
    //});
    // Change event
    //this.wizard.on("change", function (/*wizardObj*/) {
    //  setTimeout(function () {
    //    KTUtil.scrollTop();
    //  }, 500);
    //});
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "config",
      "isAuthenticated",
      "currentUser",
      "clientId",
      "logoTheme"
    ]),
  },
  methods: {
    remount() {
      const self = this;
      this.$store.dispatch(VERIFY_AUTH).then((x) => {
        try {
          if (!x) return self.$router.push("/Auth/Load");
          let lastURL = window.sessionStorage.getItem("lastURL");
          if (
            lastURL !== undefined &&
            lastURL !== null &&
            lastURL !== "" &&
            lastURL.indexOf("SCID=") > 0
          ) {
            let clientId = lastURL
              .substring(lastURL.indexOf("SCID="))
              .split("&")[0]
              .split("#")[0]
              .split("=")[1];
            self.$data.form.busy = false;
            self.selectClient(clientId);
            return;
          }
          if (!self.$tools.isNullOrUndefined(self.clientId)) {
            self.selectClient(self.clientId);
            //self.$data.form.busy = false;
            //self.restoreUrl();
            return;
          }
          if (Object.keys(self.currentUser.clients).length === 1) {
            self.$data.form.busy = false;
            self.selectClient(Object.keys(self.currentUser.clients)[0]);
            return;
          }
          if (
            self.currentUser.clients[self.$route.query.SCID || "_X_X_X_X_X"] !==
            undefined
          ) {
            self.$data.form.busy = false;
            self.selectClient(self.$route.query.SCID);
            return;
          }
          self.$data.form.busy = false;
        } catch (exc) {
          self.$log.error(exc);
          self.$data.form.busy = false;
        }
      });
    },
    forceRetry() {
      window.location.reload(true);
    },
    selectClient(clientId) {
      if ((this.currentUser.clients || {})[clientId] === undefined) {
        this.$data.form.accountId = clientId;
        const self = this;
        self.$data.form.busy = true;
        setTimeout(() => {
          self.$data.form.busy = false;
          self.$nextTick(() => {
            self.submit();
          });
        }, 5000);
        return;
      }
      //console.log("clid:" + clientId);
      const self = this;
      self.$data.form.busy = true;
      self.$store.dispatch(DO_CLIENT_CHANGE, clientId).then(() => {
        self.$api
          .getWithCache(
            self,
            "crm",
            `${ clientId }/service-keys`,
            "crm:service-keys",
            true
          )
          .then(() => {
            self.restoreUrl();
          })
          .catch((response) => {
            self.$log.error(response);
            self.$data.fullErrorMessage =
              "Problem while trying to cache services";
            self.$data.form.busy = false;
          });
      });
    },
    restoreUrl() {
      let lastURL = window.sessionStorage.getItem("lastURL");
      if (lastURL !== undefined && lastURL !== null && lastURL !== "") {
        window.sessionStorage.removeItem("lastURL");
        return this.$router.push(lastURL);
      }
      return this.$router.push("/App");
    },
    logout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "Login" }));
    },
    submit: function(e) {
      if (e !== undefined) e.preventDefault();
      if (this.form.busy) return;

      if (this.$refs.loginForm.validate()) {
        this.$data.form.busy = true;
        const self = this;
        self.$api
          .get("crm", `account/attempt-link/${ self.form.accountId }`)
          .then(() => {
            setTimeout(() => {
              self.$store.dispatch(FORCE_REFRESH_TOKEN).then(() => {
                setTimeout(() => {
                  self.remount();
                }, 1000);
              });
            }, 1000);
            /*if (x.ok) {
              self.$store.dispatch(DO_LOGIN, x.tokenData).then(() => {
                self.$nextTick(() => {
                  self.$store.dispatch(VERIFY_AUTH).then((x) => {
                    if (x) return self.restoreUrl();
                    Swal.fire({
                      title: "Error",
                      text: "A weird auth error occured",
                      icon: "error",
                      confirmButtonClass: "btn btn-danger",
                    });
                    self.$log.error(x);
                  });
                });
              });
              return;
            }
            if (x.optRequired) {
              self.$data.form.step = 4;
              self.$data.form.busy = false;
              return;
            }
            if (x.register) {
              self.$data.form.step = 1;
              self.$data.form.busy = false;
              //});
              return;
            }
            if (x.registerOTP) {
              self.$data.form.step = 6;
              self.$data.form.busy = false;
              if (x.registerOTPMessage)
                Swal.fire({
                  title: "Check your email",
                  text: "We've sent you a secret code, please check your email for it",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                });
              return;
            }
            self.$data.form.busy = false;
            self.$log.error(x);
            if (x.message !== undefined) {
              Swal.fire({
                title: "Error",
                text: x.message,
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              });
              return;
            }
            Swal.fire({
              title: "Access Denied",
              text: "Authentication failed",
              icon: "error",
              confirmButtonClass: "btn btn-danger",
            });*/
          })
          .catch((x) => {
            setTimeout(() => {
              self.$store.dispatch(FORCE_REFRESH_TOKEN).then(() => {
                if (Object.keys(self.currentUser.clients).length === 0) {
                  self.$data.form.busy = false;
                  Swal.fire({
                    title: "Woops",
                    text: "Account not found, please try again or contact support.",
                    icon: "error",
                    confirmButtonClass: "btn btn-danger",
                  });
                  self.$log.error(x);
                  return;
                }
                setTimeout(() => {
                  self.remount();
                }, 1000);
              });
            }, 1000);
          });
      }
      /*const self = this;
      if (self.wizard.currentStep == 2) {
        self.$data.stepsStage = 1;
        self.wizard.totalSteps = 4;
        self.wizard.goTo(1);
        self.$nextTick(() => {
          self.wizard.goTo(3);
        });
      } else*/ if (false === self.wizard)
        Swal.fire({
          title: "",
          text: "The application has been successfully submitted!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
    },
  },
  /*mounted() {
    if (window.addEventListener) {
      window.addEventListener("message", this.authNotification);
    } else {
      window.attachEvent("onmessage", this.authNotification);
    }
  },
  beforeDestroy() {
    if (window.addEventListener) {
      window.removeEventListener("message", this.authNotification);
    } else {
      window.detachEvent("onmessage", this.authNotification);
    }
  },
  methods: {
    authNotification(event) {
      if (typeof event.data === "object") {
        if (event.data.action === "AUTH-IFRAME") {
          console.log(event.data);
          if (event.data.status) {
            console.log("OK");
            this.$keycloak.finalAuth();
          } else {
            console.log("FAIL");
          }
        }
      }
    },
  },*/
};
</script>

<style>
#ktportsidelist .kt-portlet__body {
  flex-direction: row;
}
</style>